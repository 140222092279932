<template>
  <div class="home container">
    <div class="row p-5">
      <div class="col-xl-6" style="miheight: 512px">
        <div
          style="
            position: relative;
            left: 0;
            right: 0;
            top: 0;
            height: 512px;
            width: 512px;
            border: 1px solid black;
            margin: auto;
          "
        >
          <img
            src="@/assets/Gat.png"
            style="
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 100%;
            "
          />
          <img
            src="@/assets/Glas00.png"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            v-if="this.$store.state.glassoortKeuze == 0"
          />
          <img
            src="@/assets/Glas01.png"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            v-if="this.$store.state.glassoortKeuze == 1"
          />
          <img
            src="@/assets/Glas02.png"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            v-if="this.$store.state.glassoortKeuze == 2"
          />
          <img
            src="@/assets/Glas03.png"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            v-if="this.$store.state.glassoortKeuze == 3"
          />
          <img
            src="@/assets/Frame.png"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
          />
          <img
            src="@/assets/ala.png"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            v-if="this.$store.state.hendelKeuze == 0"
          />
          <img
            src="@/assets/vg.png"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            v-if="this.$store.state.hendelKeuze == 1"
          />

          <img
            src="@/assets/Honder00.png"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            v-if="this.$store.state.horizontaleOnderverdeling == 1"
          />

          <img
            src="@/assets/Honder01.png"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            v-if="this.$store.state.horizontaleOnderverdeling == 2"
          />

          <img
            src="@/assets/Honder02.png"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            v-if="this.$store.state.horizontaleOnderverdeling == 3"
          />

          <img
            src="@/assets/VOnder.png"
            style="
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            "
            v-if="this.$store.state.verticaleOnderverdeling == 1"
          />

          <div
            style="position: absolute; top: 10px; left: 150px; width: 300px"
            class="text-start"
          >
            x: {{ this.$store.state.draaipuntX }} mm
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <img src="@/assets/AlustaLogo.png" class="img-fluid" />
        <h2>Alusta kader</h2>
        <div class="mb-3 row">
          <label for="Bkader" class="col-sm-2 col-form-label">Width (mm)</label>
          <div class="col-sm-10">
            <input id="Bkader" class="form-control" v-model="Bkader" />
          </div>
        </div>
        <div class="mb-3 row">
          <label for="Hkader" class="col-sm-2 col-form-label"
            >Height (mm)</label
          >
          <div class="col-sm-10">
            <input id="Hkader" class="form-control" v-model="Hkader" />
          </div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-2">Glasmaat</div>
          <div class="col-sm-10">{{ Bdeur }} mm x {{ Hdeur }} mm</div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-2">Spanmaat</div>
          <div class="col-sm-10">
            {{ this.$store.state.spanmaat.Breedte }} mm x
            {{ this.$store.state.spanmaat.Hoogte }} mm
          </div>
        </div>

        <div class="mb-3 row">
          <div class="col-sm-2">Frame</div>
          <div class="col-sm-10">
            {{
              this.$store.state.aldv[this.$store.state.alustaDeurKeuze]
                .navisioncode
            }}
          </div>
        </div>

        <div class="mb-3 row">
          <label for="draaipuntX" class="col-sm-2 col-form-label"
            >Draaipunt</label
          >
          <div class="col-sm-10">
            <input id="draaipuntX" class="form-control" v-model="draaipuntX" />
          </div>
        </div>

        <div
          class="alert alert-warning"
          role="alert"
          v-if="this.$store.state.toonDraaipuntWaarschuwing"
        >
          ADVIES: We raden aan om het draaipunt meer te verplaatsen
        </div>

        <div class="mb-3 row">
          <label class="col-sm-2 col-form-label">Glassoort</label>
          <div class="col-sm-10">
            <select class="form-select" v-model="glassoortKeuze">
              <option
                v-for="(model, index) in this.$store.state.glassoort"
                :value="index"
                v-bind:key="index"
              >
                {{ model.title }}
              </option>
            </select>
          </div>
        </div>

        <div class="mb-3 row">
          <label class="col-sm-2 col-form-label">Greep</label>
          <div class="col-sm-10">
            <select class="form-select" v-model="hendelKeuze">
              <option
                v-for="(model, index) in this.$store.state.hendel"
                :value="index"
                v-bind:key="index"
              >
                {{ model.title }}
              </option>
            </select>
          </div>
        </div>
        <h2>Onderverdeling</h2>
        <div class="mb-3 row">
          <label class="col-sm-2 col-form-label"> Horizontaal </label>
          <div class="col-sm-10">
            <select class="form-select" v-model="horizontaleOnderverdeling">
              <option value="0">Geen</option>
              <option value="1">Een</option>
              <option value="2">Twee</option>
              <option value="3">Drie</option>
            </select>
          </div>
        </div>

        <div class="mb-3 row">
          <label class="col-sm-2 col-form-label"> Verticaal </label>
          <div class="col-sm-10">
            <select class="form-select" v-model="verticaleOnderverdeling">
              <option value="0">Geen</option>
              <option value="1">Een</option>
            </select>
          </div>
        </div>

        <div class="mb-3 row">
          <button class="btn btn-primary" @click="exportOrder">
            Export Order
          </button>
        </div>

        <h2>Connect with EGDesign</h2>
        <div class="mb-3 row">
          <label for="EGDUser" class="col-sm-2 col-form-label">Username</label>
          <div class="col-sm-10">
            <input id="EGDUser" class="form-control" v-model="user_name" />
          </div>
        </div>
        <div class="mb-3 row">
          <label for="EGDPwd" class="col-sm-2 col-form-label">Password</label>
          <div class="col-sm-10">
            <input
              type="password"
              id="EGDPwd"
              class="form-control"
              v-model="user_pwd"
            />
          </div>
        </div>
        <div class="mb-3 row">
          <button class="btn btn-success" @click="toEGDesign">
            Save to EGDesign
          </button>
        </div>
        <!-- col-6 //-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  computed: {
    user_name: {
      get() {
        return this.$store.state.sec.user_name;
      },
      set(value) {
        this.$store.commit("setUserName", value);
      },
    },
    user_pwd: {
      get() {
        return this.$store.state.sec.user_pwd;
      },
      set(value) {
        this.$store.commit("setUserPwd", value);
      },
    },
    glassoortKeuze: {
      get() {
        return this.$store.state.glassoortKeuze;
      },
      set(value) {
        this.$store.commit("setGlassoortKeuze", value);
      },
    },
    hendelKeuze: {
      get() {
        return this.$store.state.hendelKeuze;
      },
      set(value) {
        this.$store.commit("setHendelKeuze", value);
      },
    },
    horizontaleOnderverdeling: {
      get() {
        return this.$store.state.horizontaleOnderverdeling;
      },
      set(value) {
        this.$store.commit("setHorizontaleOnderverdeling", value);
      },
    },
    verticaleOnderverdeling: {
      get() {
        return this.$store.state.verticaleOnderverdeling;
      },
      set(value) {
        this.$store.commit("setVerticaleOnderverdeling", value);
      },
    },
    draaipuntX: {
      get() {
        return this.$store.state.draaipuntX;
      },
      set(value) {
        this.$store.commit("setDraaipuntX", value);
        this.$store.commit("calculateDraaipuntWarning");
      },
    },
    Bkader: {
      get() {
        return this.$store.state.buitenmaat.Breedte;
      },
      set(value) {
        this.$store.commit("setBreedte", value);
        this.$store.commit("calculateX");
        this.$store.commit("calculateDraaipuntWarning");
      },
    },
    Hkader: {
      get() {
        return this.$store.state.buitenmaat.Hoogte;
      },
      set(value) {
        this.$store.commit("setHoogte", value);
      },
    },
    Bdeur: {
      get() {
        return this.$store.state.glasmaat.Breedte;
      },
      set(value) {
        this.$store.commit("setGlasBreedte", value);
      },
    },
    Hdeur: {
      get() {
        return this.$store.state.glasmaat.Hoogte;
      },
      set(value) {
        this.$store.commit("setGlasHoogte", value);
      },
    },
  },
  methods: {
    async toEGDesign() {
      if (
        this.user_name != "" &&
        this.user_pwd != "" &&
        this.Bdeur > 100 &&
        this.Hdeur > 100 &&
        this.draaipuntX >= 110
      ) {
        const theOrder = await this.$store.dispatch("generateOrderJson");
        if (theOrder != 0) {
          const id = await this.$store.dispatch("saveToEGDesign", theOrder);
          if (id != 0) {
            if (confirm("Edit in EGDesign?") == true) {
              location.replace(
                "https://egdesign.euroglas-dl.com/egdesign/order.php?orderID=" +
                  id
              );
            }
          } else {
            alert("Er liep iets mis!");
          }
        }
      } else {
        alert("Vul alles correct in!");
      }
    },
    async exportOrder() {
      const theOrder = await this.$store.dispatch("generateOrderJson");
      if (theOrder != 0) {
        if (confirm("Download export file?") == true) {
          this.download("test.txt", JSON.stringify(theOrder));
        }
      } else {
        alert("Er liep iets mis!");
      }
    },
    download(filename, textInput) {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/plain;charset=utf-8, " + encodeURIComponent(textInput)
      );
      element.setAttribute("download", filename);
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
};
</script>

import { createStore } from "vuex";
import axios from "axios";

export default createStore({
  state: {
    sec: {
      user_name: "",
      user_pwd: "",
    },
    order: {
      klant: 6,
      klantref: "test",
      orderstatus: "n",
      aklant: 0,
      deurgehelen: [],
    },
    glasmaat: {
      Breedte: 0,
      Hoogte: 0,
    },
    buitenmaat: {
      Hoogte: 0,
      Breedte: 0,
    },
    spanmaat: {
      Hoogte: 0,
      Breedte: 0,
    },
    toonDraaipuntWaarschuwing: false,
    horizontaleOnderverdeling: 0,
    verticaleOnderverdeling: 0,
    glassoortKeuze: 0,
    alustaDeurKeuze: 0,
    draaipuntX: 110,
    hendelKeuze: 0,
    hendelPositie: {
      y: 0,
      x: 80,
    },
    aldv: [
      {
        type: "aldv",
        bestel: 1,
        finishID: 173,
        oorsprong: "LB",
        product: 4343,
        navisioncode: "ASDKIT10",
        x: 110,
      },
      {
        type: "aldv",
        bestel: 1,
        finishID: 173,
        oorsprong: "LB",
        product: 4344,
        navisioncode: "ASDKIT11",
        x: 110,
      },
    ],
    hendel: [
      {
        title: "Greep",
        type: "ala",
        bestel: 1,
        finishID: 173,
        oorsprong: "RO",
        product: 4340,
        navisioncode: "ASHG01",
        y: 984,
      },
      {
        type: "vg",
        title: "Trekker",
        afstand: "200",
        bestel: 1,
        diameter: 14,
        finishID: 173,
        oorsprong: "RO",
        product: 48,
        navisioncode: "AMT200300",
        x: 80,
        y: 984,
      },
      /*{
        type: "vg",
        afstand: "300",
        bestel: 1,
        diameter: 14,
        finishID: 173,
        oorsprong: "RO",
        product: 1923,
        navisioncode: "AMUR200300SQ",
        x: 80,
        y: 984,
      },*/
    ],
    ALO: [
      {
        type: "ALOH",
        bestel: 1,
        finishID: 173,
        oorsprong: "RB",
        product: 4342,
        navisioncode: "ASDIVH",
        y: 100,
      },
      {
        type: "ALOV",
        bestel: 1,
        finishID: 173,
        oorsprong: "RB",
        product: 4341,
        navisioncode: "ASDIVV",
        x: 100,
      },
    ],
    glassoort: [
      {
        title: "Klaar",
        glassoort: "A",
        type: "H10A",
        glasdikte: "10.0",
        glasdoel: "H",
        slijpen: "RP",
        geenGlas: 0,
        gehard: 1,
        bewerktekant: "",
      },
      {
        title: "Grijs",
        glassoort: "C",
        type: "H10C",
        glasdikte: "10.0",
        glasdoel: "H",
        slijpen: "RP",
        geenGlas: 0,
        gehard: 1,
        bewerktekant: "",
      },
      {
        title: "Brons",
        glassoort: "B",
        type: "H10B",
        glasdikte: "10.0",
        glasdoel: "H",
        slijpen: "RP",
        geenGlas: 0,
        gehard: 1,
        bewerktekant: "",
      },
      {
        title: "Acide Klaar",
        glassoort: "M",
        type: "H10M",
        glasdikte: "10.0",
        glasdoel: "H",
        slijpen: "RP",
        geenGlas: 0,
        gehard: 1,
        bewerktekant: "V",
      },
    ],
  },
  mutations: {
    setUserName(state, payload) {
      state.sec.user_name = payload;
    },
    setUserPwd(state, payload) {
      state.sec.user_pwd = payload;
    },
    setGlassoortKeuze(state, payload) {
      state.glassoortKeuze = parseInt(payload);
    },
    setHendelKeuze(state, payload) {
      state.hendelKeuze = parseInt(payload);
    },
    setHorizontaleOnderverdeling(state, payload) {
      state.horizontaleOnderverdeling = parseInt(payload);
    },
    setVerticaleOnderverdeling(state, payload) {
      state.verticaleOnderverdeling = parseInt(payload);
    },
    setGlasBreedte(state, payload) {
      state.glasmaat.Breedte = parseInt(payload);
    },
    setGlasHoogte(state, payload) {
      state.glasmaat.Hoogte = parseInt(payload);
    },
    setBreedte(state, payload) {
      let temp = parseInt(payload);
      if (isNaN(temp)) {
        temp = 0;
      }
      state.buitenmaat.Breedte = temp;
      state.glasmaat.Breedte = Math.floor(state.buitenmaat.Breedte - 19 - 19);
      if (state.buitenmaat.Breedte <= 900) state.alustaDeurKeuze = 0;
      else state.alustaDeurKeuze = 1;
      state.spanmaat.Breedte = state.buitenmaat.Breedte + 8;
    },
    setHoogte(state, payload) {
      let temp = parseInt(payload);
      if (isNaN(temp)) {
        temp = 0;
      }
      if (temp > 2600) {
        temp = 2600;
      }
      state.buitenmaat.Hoogte = temp;
      state.glasmaat.Hoogte = Math.floor(state.buitenmaat.Hoogte - 19 - 66);
      state.spanmaat.Hoogte = state.buitenmaat.Hoogte + 18;
    },
    setDraaipuntX(state, payload) {
      let temp = parseInt(payload);
      if (isNaN(temp)) {
        temp = 0;
      }
      if (temp < 110) {
        temp = 110;
      }
      state.draaipuntX = temp;
    },
    calculateX(state) {
      if (state.buitenmaat.Breedte < 1010) {
        state.draaipuntX = 110;
      } else {
        state.draaipuntX = state.buitenmaat.Breedte - 900;
      }
    },
    calculateDraaipuntWarning(state) {
      if (state.buitenmaat.Breedte - state.draaipuntX > 900) {
        state.toonDraaipuntWaarschuwing = true;
      } else {
        state.toonDraaipuntWaarschuwing = false;
      }
    },
  },
  actions: {
    async sso() {
      const sso = await axios.get(
        "https://egdesign.euroglas-dl.com/egdesign/api/sso.php"
      );
      console.log(sso);
    },
    async saveToEGDesign({ state }, order) {
      //Save order to egdesign
      try {
        const saveOrder = await axios.post(
          "https://api.euroglas-dl.com/",
          JSON.stringify({
            sec: {
              user_name: state.sec.user_name,
              user_pwd: state.sec.user_pwd,
            },
            controller: "saveOrder",
            order: order,
          })
        );
        return saveOrder.data.orderid;
      } catch (e) {
        console.log(e);
      }
      return 0;
    },
    async generateOrderJson({ state }) {
      try {
        const order = await axios.post(
          "https://api.euroglas-dl.com/",
          JSON.stringify({
            controller: "getEmptyOrder",
            noCache: false,
          })
        );

        const Deurgeheel = await axios.post(
          "https://api.euroglas-dl.com/",
          JSON.stringify({
            controller: "getEmptyDeurgeheel",
            noCache: false,
          })
        );
        const glassoort = state.glassoort[state.glassoortKeuze];
        Object.keys(glassoort).forEach((key) => {
          if (key in Deurgeheel.data) Deurgeheel.data[key] = glassoort[key];
        });
        Deurgeheel.data.hoogte = state.glasmaat.Hoogte;
        Deurgeheel.data.breedte = state.glasmaat.Breedte;
        Deurgeheel.data.referentie = "Alusta";
        Deurgeheel.data.aantal = 1;

        const emptyUitsparing = await axios.post(
          "https://api.euroglas-dl.com/",
          JSON.stringify({
            controller: "getEmptyUitsparing",
            noCache: false,
          })
        );
        //FRAME
        let frame = { ...emptyUitsparing.data };
        const selectedFrame = state.aldv[state.alustaDeurKeuze];
        Object.keys(selectedFrame).forEach((key) => {
          if (key in frame) frame[key] = selectedFrame[key];
        });
        frame.x = state.draaipuntX;
        Deurgeheel.data.uitsparing.push(frame);
        //HANDLE
        let handle = { ...emptyUitsparing.data };
        const hendelKeuze = state.hendel[state.hendelKeuze];
        Object.keys(hendelKeuze).forEach((key) => {
          if (key in handle) handle[key] = hendelKeuze[key];
        });
        Deurgeheel.data.uitsparing.push(handle);
        //DIVIDERS H
        let dividerH = { ...emptyUitsparing.data };
        const dividerHkeuze = state.ALO[0];
        Object.keys(dividerHkeuze).forEach((key) => {
          if (key in dividerH) dividerH[key] = dividerHkeuze[key];
        });
        if (state.horizontaleOnderverdeling > 0) {
          let dividersH1 = { ...dividerH };
          dividersH1.y = Math.floor(state.glasmaat.Hoogte / 2);
          if (state.horizontaleOnderverdeling > 1) {
            let dividersH2 = { ...dividerH };
            dividersH1.y = Math.floor(state.glasmaat.Hoogte / 3);
            dividersH2.y = Math.floor((state.glasmaat.Hoogte / 3) * 2);
            if (state.horizontaleOnderverdeling > 2) {
              let dividersH3 = { ...dividerH };
              dividersH1.y = Math.floor(state.glasmaat.Hoogte / 4);
              dividersH2.y = Math.floor(state.glasmaat.Hoogte / 2);
              dividersH3.y = Math.floor((state.glasmaat.Hoogte / 4) * 3);
              Deurgeheel.data.uitsparing.push(dividersH3);
            }
            Deurgeheel.data.uitsparing.push(dividersH2);
          }
          Deurgeheel.data.uitsparing.push(dividersH1);
        }
        //DIVIDER V
        if (state.verticaleOnderverdeling == 1) {
          let dividerV = { ...emptyUitsparing.data };
          const dividerVkeuze = state.ALO[1];
          Object.keys(dividerVkeuze).forEach((key) => {
            if (key in dividerV) dividerV[key] = dividerVkeuze[key];
          });
          dividerV.x = state.glasmaat.Breedte / 2;
          Deurgeheel.data.uitsparing.push(dividerV);
        }
        //Fill Order
        order.data.deurgehelen.push(Deurgeheel.data);
        return order.data;
      } catch (e) {
        console.log(e);
      }
      return 0;
    },
  },
  modules: {},
});
